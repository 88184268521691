<template>
    <v-container class="pa-0" fluid>
        <page-title
                :icon="icon"
                :permissionAdd="this.$store.getters['permissions/checkPermission']('manageUserRoles')"
                :heading="$t('settings.lang_roles')"
                show-add-button
                :subheading="$t('settings.lang_roles')"
        ></page-title>
        <div class="app-main__inner">
            <role-component/>
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import RoleComponent from "../../../../components/settings/basic/roles/RoleComponent";


    export default {
        components: {
            RoleComponent,

            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-users icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>